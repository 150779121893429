.LaboratoryActivity {
  font-size: 16px;
}

.LaboratoryActivity .ActivityName {
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: var(--dark-indigo);
  padding-bottom:10px;
}

.LaboratoryActivity .ActivityItem {
  font-weight: normal;
}

.LaboratoryActivity .ActivityItem h3 {
  padding-top:10px;
}

.DropdownPane .Separator {
  margin-top: 4px;
  margin-bottom: 10px;
  width:100%;
  line-height: 0.25;
  border: solid 0.5px #a8e9e7;
}

.LaboratoryActivity .ActivityItem .Remark {
  display: grid;
  grid-template-columns: 32px auto;
  grid-column-gap: 10px;
  align-items: center;
  padding-top: 10px;
}

.LaboratoryActivity .ActivityItem .Remark .Marker {
  color: #d8d8d8;
  font-size:32px;
}

.LaboratoryActivity .ActivityItem .Description {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
}

.LaboratoryActivity .ActivityItem .Description .Text {
  font-size:16px;
  margin-top:4px;
  margin-bottom:4px;
}

.LaboratoryActivity .ActivityDescriptionContainer {
  display: grid;
  grid-template-columns: 32px 400px;
  grid-column-gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
}

.LaboratoryActivity .ActivityDescriptionMarker {
  color: #d8d8d8;
  font-size:32px;
}

.LaboratoryActivity .ActivityDescription {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--navy);
  width:75%;
  white-space: pre-wrap;
}

.LaboratoryActivitySeparator {
  padding-bottom:40px;
}
