.PaymentMethodForm {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.PaymentMethodForm .StripeLogo {
  margin: .5em .5em .5em auto;
}
