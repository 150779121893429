.RowFlexWrapper {
  display: flex;
  background-color: #ffffff;
  margin-top: 20px;
  padding-bottom: 40px;
  padding-top: 40px;
  padding-left: 60px;
  padding-right: 60px;
}

.FormRowWrapper {
  width: 350px;
}

.CaseDataFormRow {
  display: flex;
  align-items: center;
}

.CaseDataFormRow:first-of-type {
  margin-bottom: 20px;
}

.CaseDataFormRow span:first-of-type {
  width: 250px;
}

.CaseDataFormRow span {
  margin-left: 10px;
  width: 150px;
  justify-self: left;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--denim);
}

.CaseDataFormRow input {
  margin-left: 10px;
  width: 85px;
  text-align: left;
  padding-left: 6px;
  padding-right: 0;
  color: var(--denim);
}

.CaseDataForm .FormRowWrapper[disabled] {
  opacity: 0.7;
}

.CaseDataForm .SelectorWrapper {
  display: flex;
}

.CaseDataForm .SubstanceSelector {
  text-align: left;
  width: 665px;
}

.CaseDataForm .SubstanceSelector div {
  z-index: 2000;
}

.CaseDataForm .SearchIcon {
  margin-left: 10px;
}

.CaseDataForm .Disclaimer {
  position: relative;
  width: 250px;
  min-height: 80px;
  border: solid 2px var(--pale-teal);
  color: var(--denim);
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
}

.CaseDataForm .Disclaimer .Oval {
  width: 36px;
  height: 36px;
  border: solid 2px var(--pale-teal);
  position: absolute;
  top: 20px;
  left: -20px;
  border-radius: 19px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CaseDataForm .SubstanceSelector input {
  font-size: 18px;
  font-weight: bold;
  color: var(--dark-indigo);
}

.CaseDataForm .ButtonContainer {
  background-color: #ffffff;
  display: flex;
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 40px;
}

.CaseDataForm button {
  margin-right:5px;
  margin-top: 0;
}

.CaseDataForm .SubstanceTree {
  margin-top: 20px;
}

.CaseDataForm .SubstanceTree .Selected {
  font-weight: bold;
}

.CaseDataForm .SubstanceTree div {
  line-height: 1.13;
  font-size: 16px;
  color: var(--navy);
}

.CaseDataForm .SubstanceTree .Name {
  text-decoration: underline;
}

.CaseDataForm .SubstanceTree .Node {
  margin-left: 12px;
  margin-top: 6px;
}

.CaseDataForm .SubstanceTree .Node .Name {
  cursor: pointer;
}

.CaseDataForm .SubstanceTree .Node.Level0 {
  margin-left: 0;
}

.CaseDataForm .SubstanceTree .Parent {
  font-style: italic;
  cursor: default;
}

.CaseDataForm .SubstanceTree .Arrow:before {
  content:'\25BA';
  font-size: 8px;
  margin-right: 8px;
  vertical-align: middle;
  color: var(--denim);
}
