.AccountPage hr {
  border: 0;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 1em 0;
}

.AccountPage div.ErrorText {
  padding: 1em 0;
  color: var(--clevor-red);
}

.AccountPage .DeleteAccount.ConfirmButton {
  background-color: var(--clevor-red);
}

.AccountPage p {
  padding: 1em 0;
}

.AccountPage label {
  margin: 1em 0;
  display: flex;
  flex-direction: column;
}

.AccountPage .Success {
  color: green;
  font-weight: bold;
  margin: 1em 0;
}

.AccountPage .Error {
  color: var(--clevor-red);
  font-weight: bold;
  margin: 1em 0;
}

.AccountPage .Subscription dl, .AccountPage .BillingDetails dl {
  margin: 1em 0;
}

.AccountPage .Subscription dt, .AccountPage .BillingDetails dt {
  color: var(--denim);
  font-weight: 500;
  margin: 1em 0 0 0;
}

.AccountPage .Subscription dd, .AccountPage .BillingDetails dd {
  font-style: italic;
}

.AccountPage .Subscription span.OverDue {
  color: var(--clevor-red);
}

.AccountPage .BillingDetails dd span.Brand {
  padding-right: 0.5em;
}

.AccountPage .BillingDetails dd span.ExpiresSoon {
  color: var(--clevor-red);
  padding-left: 0.5em;
}

.AccountPage .BillingDetails .LoadingIndicator {
  padding: 0.5em 0;
}

.AccountPage .BillingDetails button {
  margin-top: 2px;
}

.AccountPage .BillingDetails button.CancelButton {
  margin-left: 12px;
  background-color: transparent;
  border-color: var(--denim);
  color: var(--denim);
}
