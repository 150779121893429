.BillingDetails section {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.BillingDetails label {
  margin: 2px 0;
  width: 100%;
  padding: 0.5em 0;
}

.BillingInformationForm label.TwoThirds {
  padding-left: 0.5em;
  box-sizing: border-box;
}

.BillingDetails div.ErrorText {
  padding: 0 0 1em 0;
  color: var(--clevor-red);
}

.BillingDetails .validationErrorText {
  margin-bottom: 24px;
  padding-top: 2px;
  font-size: .8rem;
  color: var(--clevor-red);
}
