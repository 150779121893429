.CaseDetails .ToxicityGraphWrapper {
  max-width: var(--body-text-width);
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 40px;
}

.CaseDetails .ClinicalSignListWrapper {
  margin-top: 50px;
}

.CaseDetails .TreatmentPlanWrapper {
  margin-top: 50px;
}