.LegalDisclaimer {
  padding:10px 10px 10px 10px;
  margin:50px auto;
  color:black;
  max-width:1000px;
}

.LegalDisclaimer h3 {
  margin-bottom:0.5em;
}

.LegalDisclaimer p {
  margin-bottom: 10px;
}