@media (max-width: 1024px) {
    .App > .Content {
        width: 90vw;
        padding-right: 5vw;
        padding-left: 5vw;
        overflow: scroll;
    }
    
    .Login {
        width: 90vw;
    }
    
    .Login .TaglineWrapper {
        background: none;
        min-height: 0;
    }
    
    .Login .Tagline {
        font-size: 40px;
    }
    
    .App .WhiteTopBar {
        padding-left: 10px;
    }
    
    .App .grid {
        width: 90vw;
    }
    
    .Login .grid input {
        width: 40vw;
    }
    
    .App .MainFooter {
        height: auto;
    }
    
    .App .MainFooter .MainFooterContent {
        display: block;
        text-align: center;
        height: auto;
    }
    
    .App .MainFooter .MainFooterContent .FooterLogo {
        position: relative;
        display: block;
        margin: 20px auto;
    }
    
    .App .MainFooter .MainFooterContent {
        line-height: 30px;
    }
    
    .App .MainFooter .WhiteFooter {
        background-color: transparent;
    }
    
    .App .MainFooter .FooterSlice {
        display: none;
    }
    
    .App .MainViewLayout, .App .WelcomeWrapper, .App .FeedbackWrapper {
        min-width: 80vw;
        max-width: 90vw;
    }
    
    .App .WelcomeWrapper, .App .FeedbackWrapper {
        width: 80vw;
    }
    
    .App .RowFlexWrapper {
        flex-wrap: wrap;
        padding: 10px;
    }
    
    .App .CaseDataForm .ButtonContainer {
        padding: 10px;
        flex-wrap: wrap;
    }
    
    .App .CaseDataForm .Disclaimer {
        width: 200px;
        margin-top: 10px;
        margin-left: 20px;
    }
    
    .App > .TopBar {
        height: auto;
        border-radius: 0;
    }
    
    .App > .TopBar .TopBarWrapper{
        height: auto;
        border-radius: 0;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    
    .App .TopBar .title {
        padding-left: 0;
    }
    
    .App .TopBar .title img {
        width: 100px;
    }
    
    .MobileNaviIcon {
        padding-left: 10px;
        padding-right: 10px;
    }
    
    .MobileLinks {
        width: 100vw;
    }
    
    .MobileLink {
        padding-left: 10px;
        margin-bottom: 10px;
    }
    
    .MobileLink, .MobileLink a, .MobileLink a:visited {
        color: var(--clevor-blue);
        text-decoration: none;
        font-size: 16px;
        font-weight: bold;
    }
    
    .App .Feedback textarea {
        width: 90%;
    }
    
    .App .MainView {
        width: 90vw;
    }
    
    .App .MainViewWelcomeWrapper .WelcomeWrapper {
        display: block;
        margin-top: 0px;
        padding-top: 10px;
    }
    
    .App .CaseDetails .ToxicityGraphWrapper {
        padding: 10px;
        margin-right: 0;
        max-width: 100%;
    }
    
    .App .ToxicityGraph .Gradient {
        margin-left: 40px;
        margin-right: 40px;
    }
    
    .App .ToxicityGraph .Box .DoseContainer {
        display: flex;
        flex-wrap: wrap;
    }
    
    .App .ClinicalSignList .ClinicalSignChunck {
        width: 100%;
    }
    
    .App .TreatmentPlan .DropdownPane {
        padding: 10px;
    }
    
    .App .SubstanceInfoWrapper {
        margin-left: 0;
    }
    
    .App .SubstanceInfoContent {
        background-color: var(--app-bg-color);
    }
    
    .App .ToxinIndex {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 20px;
        padding-top: 0;
        width: calc(90vw - 40px);
    }
    
    .App .TopBar .links a {
        margin-left: 0;
        margin-right: 20px;
        font-size: 14px;
    }
}