.SignUpForm {
  display: flex;
  flex-direction: column;
  background: url('../../../images/koira.png') top right no-repeat;
}

.SignUpForm section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -0.5em;
}

.SignUpFormRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.SignUpForm label {
  width: 100%;
  padding: .3em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.SignUpForm .EmailsNotEqual {
  border: 2px solid red;
}

.SignUpForm label.Horizontal {
  align-items: center;
  flex-direction: row;
}

.SignUpForm label.us-subscription {
  color: var(--clevor-red);
  font-size: 1em;
  margin: 0;
  padding: 0 6px;
}

.SignUpForm input[type="radio"],
.SignUpForm input[type="checkbox"] {
  margin-right: .5em;
}

.SignUpForm hr {
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  border: none;
  margin: 1.3em 0;
}

.SignUpForm label.HalfWide {
    width: 50%;
}

.SignUpForm a, .SignUpForm a:visited {
    color: var(--clevor-blue);
}

.SignUpForm .SubscriptionTotal {
  display: flex;
  flex-direction: column;
  margin: .5em;
}

.SignUpForm .SubscriptionPrice {
  margin: .5em 0;
}

.SignUpForm .SubscriptionNormalPrice {
  font-size: .8em;
  margin-left: 0.3em;
}

.SignUpForm .TrialPeriod {
  font-size: .8em;
  padding: 0.5em;
}

.SignUpForm .validationErrorText {
  padding-top: 2px;
  font-size: .8rem;
  color: var(--clevor-red);
}

.SignUpForm ::placeholder {
  color: #CCCCCC;
}

