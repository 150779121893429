:root {
  --robin-s-egg-blue: #a8e9e7;
  --pale-peach: #ede2c3;
  --denim: #385b84;
  --navy: #051c2c;
  --dark-indigo: var(--clevor-blue);
  --cloudy-blue: #b1bccb;
  --pale-red: var(--clevor-red);
  --light-pale-red: #e26c61;
  --pale-cyan: #c3edec;
  --death-red: #6d130c;
  --friendly-mint: #e4f1f1;
  --purple-haze: #966fbd;

  --non-toxic: var(--robin-s-egg-blue);
  --mild: var(--pale-peach);
  --moderate: var(--pale-peach);
  --high: var(--light-pale-red);
  --lethal: var(--death-red);
  --unknown: var(--purple-haze);

  --non-toxic-font: #72a4a2;
  --mild-font: #cdb162;
  --moderate-font: #cd9062;
  --high-font: var(--light-pale-red);
  --lethal-font: var(--pale-red);

  --top-bar-height: 100px;
  --app-bg-color: rgb(249, 249, 249);
  --header-letter-spacing: 0.03ch;
  --header-add-bottom-margin: 3px;

  --case-details-activity-background-color: #1c3e6b;

  --body-text-width: 95%;

  --pale-teal: #93cac9;
  --footer-height: 100px;

  --clevor-blue: #193762;
  --clevor-red: #e65041;
}

.Loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

#loading-bar-spinner.spinner {
  left: 50%;
  margin-left: -20px;
  top: 50%;
  margin-top: -20px;
  position: absolute;
  z-index: 19 !important;
  animation: loading-bar-spinner 400ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
  width: 40px;
  height: 40px;
  border:  solid 4px transparent;
  border-top-color:  #00C8B1 !important;
  border-left-color: #00C8B1 !important;
  border-radius: 50%;
}

@keyframes loading-bar-spinner {
  0%   { transform: rotate(0deg);   transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
}

html {
  scroll-behavior: smooth;
}

.App {
  top: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--app-bg-color);
}

.App > .TopBar {
  width: 100vw;
  height: var(--top-bar-height);
}

.App > .Content {
  width: 100vw;
  min-height: calc(100vh - var(--top-bar-height) - var(--footer-height) - 145px);
}

.preserve-line-breaks {
  white-space: pre-line;
}

.App.staging {
  background: url('../images/staging.svg') no-repeat center;
  background-size: cover;
}

.App .MainFooter {
  width: 100vw;
  height: var(--footer-height);
  padding-top: 20px;
}

.App .MainFooter .FooterSlice {
  background: url('../images/footer.svg');
  background-size: cover;
  height: 80px;
  width: 100vw;
  margin-bottom: -6px;
}

.App .MainFooter .WhiteFooter {
  background-color: #ffffff;
}

.App .MainFooter .MainFooterContent {
  max-width: 1000px;
  margin: 0 auto;
  height: var(--footer-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: var(--footer-height);
}

.App .MainFooter .MainFooterContent a, .App .MainFooter .MainFooterContent {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--denim);
}

.App .MainFooter .MainFooterContent .FooterLogo {
  height: 40px;
}

/* Common classes */
.RoundButton {
  margin:2px;
  padding:0;
  width:28px;
  height:28px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  border-radius:14px;
}

.AddButton {
  font-size: 14px;
}

.RemoveButton {
  background-color: var(--clevor-red);
}

.RemoveButton.ConfirmButton, .ConfirmButton {
  background-color: darkred;
}

.ConfirmRemoveWrapper {
  display: inline-block;
}

.PageContainer {
  max-width: 600px;
  margin-top: 50px;
  margin-right: auto;
  margin-left: auto;
}

.ValidationError {
  color: var(--clevor-red);
}
