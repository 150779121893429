.ToxicityLevels {
  margin-top:10px;
  display:grid;
  grid-template-columns: 110px 70px 1fr 35px;
  grid-column-gap: 10px;
}

.ToxicityLevels button {
  margin-top:2px;
}

.ToxicityLevels .ButtonContainer {
  grid-column-start: 1;
  grid-column-end: 3;
}

.ToxicityLevels .CreateButton {
   height:32px;
  grid-column-start: 1;
  grid-column-end: 3;
 }

.ToxicityLevels .EditButton {
  width:112px;
  height:32px;
  cursor: pointer;
  background-color: var(--denim);
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  text-align: center;
  color: #ffffff;
}

.ToxicityLevels .DescriptionSigns {
  margin:0px;
}

.ToxicityLevels .DescriptionSigns textarea {
  margin-bottom:0px;
  padding-top:2px;
  padding-left:2px;
}