.SubstanceEditor {
  position:fixed;
  background-color: white;
  padding:10px 10px 10px 10px;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:50%;
  min-height:85%;
  max-height:100%;
  border-radius: 10px;
}

.SubstanceEditor .Content {
  background-color: var(--app-bg-color);
  padding:10px;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 100px;
  overflow-y: scroll;
}

.SubstanceEditor .SubstanceName {
  width:100%;
}

.SubstanceEditor textarea {
  width: calc(100% - 14px);
}


.SubstanceEditor .BottomBar {
  position:fixed;
  bottom:0;
  padding: 10px;
  margin: 0;
}

.SubstanceEditor .BottomBar button {
  margin: 0;
}

.SubstanceEditor .BottomBar .Saved {
  margin-top:0px;
  color:green;
  font-style:italic;
  text-align: center;
}

.SubstanceEditor .BottomBar .Failed {
  margin-top:0px;
  color:var(--clevor-red);
  font-weight: bold;
  text-align: center;
}

.SubstanceEditor .Conflict {
  margin-top:10px;
  color:var(--clevor-red);
  font-weight: bold;
  text-align: left;
}

.SubstanceEditor h2 {
  margin-top:15px;
}

.SubstanceEditor .Synonyms {
  margin-top:10px;
  display:grid;
  grid-template-columns: 28px 1fr 28px;
  align-items: center;
  grid-row-gap: 5px;
  grid-column-gap: 5px;
}

.SubstanceEditor .Synonyms input {
  width:300px;
}

.SubstanceEditor .YesNoToggleWrapper {
  display: flex;
  align-items: center;
}

.SubstanceEditor .YesNoToggleWrapper input {
  margin-left: 15px;
  margin-right: 5px;
}

.SubstanceEditor .YesNoToggleWrapper input:first-child {
  margin-left: 0;
}

.SubstanceEditor .InheritedLink {
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--denim);
    text-decoration: underline;
}

.SubstanceEditor .InheritedContent {
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--denim);
    font-style: italic;
}

.SubstanceEditor .InheritedCategoryTitle {
  font-style: normal;
  font-weight: bold;
}

.SubstanceEditor .InheritedContent ul {
  padding-left: 30px;
}

.SubstanceEditor .InheritedContent li {
  list-style-type: disc;
}

.SubstanceEditor .StaticToxicityLevelWrapper {
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed var(--denim);
  flex-wrap: wrap;
}

.SubstanceEditor .StaticToxicityLevelCategory {
  font-style: normal;
  font-weight: bold;
  width: 20%;
}

.SubstanceEditor .StaticToxicityLevelThreshold {
  font-style: normal;
  width: 10%;
}

.SubstanceEditor .StaticToxicityLevelDescription {
  width: 70%;
}

.SubstanceEditor .StaticToxicityLevelConditionsTitle {
  font-style: normal;
  margin-top: 5px;
  width: 100%;
}

.SubstanceEditor .DescriptionParentNote {
  font-size: 14px;
  font-style: italic;
  margin-top: 10px;
}

.SubstanceEditor .InheritedFrom {
  margin-bottom: 5px;
  margin-top: 10px;
  font-size: 14px;
  color: var(--denim);
  text-transform: uppercase;
}
