.AcceptTermsContainer {
  padding:100px;
  margin:50px auto;
  color:black;
  max-width:1000px;
  background-color: #ffffff;
}

.AcceptTermsContainer a, .AcceptTermsContainer a:visited {
  color: var(--clevor-blue);
}

.AcceptTermsContainer button {
  margin-right: 20px;
}

.AcceptTermsContainer p {
  margin-bottom: 50px;
}

.AcceptTermsContainer .LogoutButton {
  background-color: var(--clevor-red);
}