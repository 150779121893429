.ToxicityGraph {
  display: flex;
  flex-direction: column;
}

.ToxicityGraph .Gradient {
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
  height: 16px;
}

.ToxicityGraph .Gradient .Dose {
  position: absolute;
  width: 115px;
  display: flex;
  font-size: 14px;
  transform: translate(-20%, -250%);
  font-weight: bold;
}

.ToxicityGraph .Gradient .Dose .Unit {
  margin-left: 5px;
}

.ToxicityGraph .Indicator {
  position:absolute;
  width: 51px;
  height: 51px;
  transform: translate(-25%, -50%);
  background-image: url('/src/images/indicator.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 1001;
}

.ToxicityGraph .Box {
  margin: 30px 0px 0 0;
  padding: 20px;
  color: black;
  border: 2px solid;
}

.ToxicityGraph .Box .DoseIngestedDescription {
  white-space: pre-wrap;
}

.ToxicityGraph .Box .Category {
  font-size: 20px;
  margin-bottom: 1rem;
}

.ToxicityGraph .Box.CaseSpecific,
.ToxicityGraph .Box.Unknown {
  border-color: var(--unknown);
}
.ToxicityGraph .Box.CaseSpecific .Category,
.ToxicityGraph .Box.Unknown .Category {
  color: var(--unknown);
}

.ToxicityGraph .Box.NonToxic {
  border-color: var(--non-toxic-font);
}
.ToxicityGraph .Box.NonToxic .Category {
  color: var(--non-toxic-font);
}

.ToxicityGraph .Box.Mild {
  border-color: var(--mild-font);
}
.ToxicityGraph .Box.Mild .Category {
    color: var(--mild-font);
}

.ToxicityGraph .Box.Moderate {
  border-color: var(--moderate-font);
}
.ToxicityGraph .Box.Moderate .Category {
    color: var(--moderate-font);
}

.ToxicityGraph .Box.High {
  border-color: var(--high-font);
}
.ToxicityGraph .Box.High .Category {
    color: var(--high-font);
}

.ToxicityGraph .Box.Lethal {
  border-color: var(--lethal-font);
}
.ToxicityGraph .Box.Lethal .Category {
    color: var(--lethal-font);
}

.ToxicityGraph .Box .DoseContainer {
  grid-auto-columns: max-content;
  grid-column-gap: 0px;
  display:grid;
  margin-bottom: 1rem;
}

.ToxicityGraph .Box .DoseContainer .Dose {
  grid-column: 1;
  font-weight: bold;
}

.ToxicityGraph .Box .DoseContainer .SubDose  {
  margin-left:30px;
  font-style: italic;
  grid-column: 1;
}

.ToxicityGraph .Box .DoseContainer .Amount {
  grid-column: 2;
  grid-auto-columns: max-content;
  display:grid;
}

.ToxicityGraph .Box .DoseContainer .Number {
  margin-left:10px;
  grid-column: 3;
  text-align: right;
}

.ToxicityGraph .Box .DoseContainer .Fractional {
  grid-column: 4;
  text-align: left;
}

.ToxicityGraph .Box .DoseContainer .Unit {
  margin-left: 5px;
  grid-column: 5;
}

.ToxicityGraph .Box .Category {
  font-weight:bold;
  grid-column: 1;
}

.ToxicityGraph .ToxicityGraphConditionWrapper {
  position: absolute;
  display: flex;
  align-items: flex-end;
}

.ToxicityGraph .ToxicityGraphConditionWrapper.LeftSide {
  border-left: 1px solid #000000;
}

.ToxicityGraph .ToxicityGraphConditionWrapper.RightSide {
  border-right: 1px solid #000000;
  text-align: right;
}

.ToxicityGraph .Condition {
  font-size: 12px;
  background-color: #ffffff;
  padding-right: 5px;
  padding-left: 5px;
}
