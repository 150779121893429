.ModalFeedback {
  position:fixed;
  background-color: white;
  padding:10px 10px 10px 10px;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  border-radius: 10px;
  margin:0px;
  color:black;
}
