.Feedback {
  max-width: 1200px;
  margin: 0 auto;
}

.Feedback .FeedbackContent {
  max-width: 560px;
  margin: 70px auto 0;
}

.Feedback p {
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--navy);
}

.Feedback form {
  margin-top: 22px;
}

.Feedback textarea {
  width: 100%;
}

.FeedbackMeta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.FeedbackMeta .TextInputFieldWrapper {
  margin-top: 10px;
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.FeedbackMeta .TextInputField {
  width: 49%;
}

.FeedbackMeta .FeedbackMetaTitle {
  margin-bottom: 1em;
}

.FeedbackMeta .CheckboxInputField input {
  margin-right: 5px;
  height: 16px;
}

.Feedback .Buttons {
  margin-top:0px;
  margin-bottom:20px;
}

.Feedback .Buttons button {
  width:112px;
  height:44px;
  cursor: pointer;
  background-color: var(--denim);
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  text-align: center;
  color: #ffffff;
  margin-top: 26px;
  margin-right: 10px;
}

.Feedback .ReportSent {
  width:100%;
  grid-column-start: 2;
  margin-top:10px;
  color:green;
  font-weight:bold;
  display: flex;
}

.Feedback .Error {
  width:100%;
  color:var(--clevor-red);
  font-weight:bold;
  margin-top:10px;
  display: flex;
}

.Feedback .usForm {
  margin-top: 22px;
}

.Feedback .additionalContact {
  padding-top: 12px;
  padding-bottom: 12px;
}
