.ClinicalSignList .ClinicalSignListContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.ClinicalSignList .ClinicalSignListHeading {
  width: 100%;
  font-size: 18px;
  color: var(--dark-indigo);
  font-weight: bold;
  margin-top: 40px;
}

.ClinicalSignList .Separator {
  width: 100%;
}

.ClinicalSignList .ClinicalSignChunck {
  width: 20%;
}

.ClinicalSignList .ClinicalSignChunck .ClinicalSign {
  margin-left: 0;
  min-height: 25px;
  font-size: 16px;
  font-weight: 600;
  color: var(--denim);
  display: block;
}

.ClinicalSignList .ClinicalSign:before {
  content: "•";
  padding-right: 6px;
  font-size: 18px;
  color: var(--denim);
}

.ClinicalSignList .ClinicalSignListDescription {
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.38;
  color: var(--navy);
  white-space: pre-wrap;
}
