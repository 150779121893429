.ClinicalSignGroupingEditor {
}

.ClinicalSignGroupingEditor .DescriptionEdit {
  margin-top:12px;
  margin-bottom:12px;
}

.ClinicalSignGroupingEditor .Description {
  padding-top:10px;
  padding-bottom:10px;
}

.ClinicalSignGroupingEditor .SignList .ButtonContainer {
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: 0;
  padding: 0;
  background-color: transparent;
}

.ClinicalSignGroupingEditor .CreateButton {
  height:32px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.ClinicalSignGroupingEditor .Group .Heading {
  display:grid;
  grid-template-columns: 32px 1fr;
  align-items: center;
}

.ClinicalSignGroupingEditor .Group .SignList {
  display:grid;
  grid-template-columns: 32px 1fr 32px;
  align-items: center;
  margin-left:34px;
}

.ClinicalSignGroupingEditor .Group .SignList span {
  margin:2px;
}

.ClinicalSignGroupingEditor .Group .DefaultSignList {
  display:grid;
  grid-template-columns: 34px 1fr;
  align-items: center;
  margin-left:36px;
}

.ClinicalSignGroupingEditor .Group .DefaultSignList .Marker {
  text-align: center;
  margin:5px;
  font-size:5px;
}

.ClinicalSignGroupingEditor .Group span {
  margin:10px;
  padding-left:5px;
}

.ClinicalSignGroupingEditor .Group select {
  margin-left:5px;
}