.ClinicalSignEditor {
  position:fixed;
  background-color: white;
  padding:10px;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  display:grid;
  grid-template-columns: 1fr 120px 120px;
}

.ClinicalSignEditor textarea {
  margin:0;
  grid-column-start: 1;
  grid-column-end: 4;
}

.ClinicalSignEditor input {
  margin:0;
  grid-column-start: 1;
  grid-column-end: 4;
}

.ClinicalSignEditor label {
  margin-top:5px;
  grid-column-start: 1;
  grid-column-end: 4;
}

.ClinicalSignEditor .CancelButton {
  width:112px;
  height:32px;
  cursor: pointer;
  background-color: var(--pale-red);
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  text-align: center;
  color: #ffffff;
  grid-column: 2;
}

.ClinicalSignEditor .CreateSignButton {
  height:28px;
  cursor: pointer;
  background-color: var(--denim);
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  text-align: center;
  color: #ffffff;
  grid-column: 3;
}