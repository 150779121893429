.Modal .Background {
  position:fixed;
  z-index:100000;
  top:-100px;
  left:0px;
  right:0;
  bottom:-100px;
  background-color:black;
  opacity: 0.4;
}

.Modal .Content {
  position:fixed;
  z-index:100001;
}