.Checklist {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: var(--navy);
}

.Checklist .Item {
  display: grid;
  grid-template-columns: 32px auto;
  grid-column-gap: 10px;
  align-items: flex-start;
  padding-bottom: 10px;
}

.Checklist .Notes {
  color: #000000;
  padding-top: 5px;
  padding-left: 20px;
  display: block;
  grid-column-start: 1;
  grid-column-end: 3;
}

.Checklist .Notes .Item:before {
  content: "•";
  font-size: 16px;
  color: var(--denim);
}


.Checklist .Notes .Heading {
  margin-left:-20px;
  margin-bottom:5px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--denim);
}

.Checklist .Notes .Item {
  margin-left: -20px;
  max-width: 600px;
  grid-column-gap: 0;
  grid-template-columns: 20px auto;

}

.Checklist .Notes .Item svg.undefined.Note {
  color: var(--denim);
  padding-left: 15px;
}

.Checklist .Item .Do {
  color: #a8e9e7;
  font-size: 40px;
  margin-top: -6px;
}

.Checklist .Item .Donts {
  color: var(--pale-red);
  font-size: 40px;
  margin-top: -6px;
}

.Checklist .Item .Note {
  color: #000000;
  font-size: 7px;
  padding-left: 20px;
  padding-top: 10px;
}
