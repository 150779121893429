.ActivityEditor button {
  height:28px;
  font-size: 14px;
  border-radius:14px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  max-width: 150px;
}

.ActivityEditor {
  position:relative;
}

.ActivityEditor .EditButton {
  width:100px;
}

.ActivityEditor .DeleteButton {
  width:100px;
  margin-left:5px;
}

.ActivityEditor .CreateItem {
  width:120px;
}

.ActivityEditor .MoveButton {
  margin-left:5px;
  margin-top:12px;
}

.ActivityEditor .Dialog {
  position:fixed;
  background-color: white;
  padding:10px 10px 80px 10px;
  top:50%;
  left:50%;
  transform:translate(-50%,-44%);
  width:50%;
  min-height:70%;
  max-height:75%;
  border-radius: 10px;
}

.ActivityEditor .Content {
  display:grid;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 100px;
  grid-template-columns: 1fr 120px 120px;
  overflow-y: scroll;
}

.ActivityEditor .Dialog .Content textarea {
  margin:0;
  grid-column-start: 1;
  grid-column-end: 4;
  height:100px;
}

.ActivityEditor .Dialog .Content .TextInput {
  margin:0;
  grid-column-start: 1;
  grid-column-end: 4;
}

.ActivityEditor .Dialog .Content label {
  margin-top:5px;
  grid-column-start: 1;
  grid-column-end: 4;
}

.ActivityEditor .BottomBar {
  position:fixed;
  bottom:10px;
  padding:10px;
}

.ActivityEditor .BottomBar .Saved {
  grid-column-start: 1;
  grid-column-end: 2;
  color:green;
  font-weight:bold;
}

.ActivityEditor .BottomBar .Failed {
  grid-column-start: 1;
  grid-column-end: 2;
  color:var(--clevor-red);
  font-weight:bold;
}

.ActivityEditor .BottomBar .CloseButton {
  width:112px;
  height:32px;
  grid-column-start: 1;
  grid-column-end: 2;
}

.ActivityEditor .InheritedActivity {
  color: var(--dark-indigo);
  border: 2px solid var(--dark-indigo);
  background-color: #ffffff;
  border-radius:5px;
  width:150px;
  padding:5px;
  margin: 25px 0 0 0;
  text-align: center;
}