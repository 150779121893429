:root {
  --substance-info-toggle-width: 40px;
  --main-view-padding-left: 20%;
}

.Loading {
  display: grid;
  align-content: center;
  text-align: center;
  font-size: 48px;
  z-index: 10000;
  position:fixed;
  left:0px;
  right:0px;
  top:0px;
  bottom:0px;
  color:var(--denim);
  background-color: white;
}

.MainView {
  width: 100vw;
}

.SubstanceInfoContent {
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  transition: all 0.8s;
  -webkit-transition: all 0.8s;
  background-color: #ffffff;
}

.SubstanceInfoContent .Divider {
  margin-left:50px;
  margin-top:3px;
  margin-bottom:3px;
  max-width: 1px;
  background-color: #dfdfdf;
}

.SeparatorWrapper {
  width: 30px;
}

.MainViewLayout, .WelcomeWrapper, .FeedbackWrapper, .SubstanceInfoWrapper {
  margin:auto;
  min-width:700px;
  max-width:1000px;
}

.MainViewWelcomeWrapper {
  background-color: #ffffff;
}

.MainViewLayout .CaseDetailsWrapper {
  margin-bottom: 10vh;
}

.MainViewWelcomeWrapper .WelcomeWrapper {
  margin-top: 50px;
  border-bottom: 0.7px solid var(--cloudy-blue);
  padding-bottom: 20px;
  margin-bottom: 60px;
  grid-template-columns: 70% 30%;
  display: grid;
}

.MainViewWelcomeWrapper .WelcomeWrapper h1 {
  color: var(--denim);
}

.MainViewWelcomeWrapper .WelcomeWrapper img {
  max-width: 100%;
}

.MainViewWelcomeWrapper .WelcomeWrapper div {
  grid-column: 1;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--dark-indigo);
  margin-top: 40px;
  margin-bottom: 20px;
}

.MainViewWelcomeWrapper .WelcomeWrapper p {
  width: 70%;
  margin-bottom: 30px;
}

.FeedbackWrapper .Feedback .FeedbackContent {
  margin: 70px 0 0;
}

.SubstanceInfoWrapper.partial {
  width: 90px;
}

.SubstanceInfoWrapper.full {
  width: 95%;
}
