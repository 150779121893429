.PageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Form {
  display: flex;
  flex-direction: column;
}

.FormRow {
  display: flex;
  flex-direction: column;
  padding: 1em 0 0.5em 0;
}

.Form label {
  display: inline-block;
  width: 25em;
}

.Form input {
  width: 100%;
}

.EmailSentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Email {
  font-weight: bold;
  padding: 0.2em 0;
}
