.SignUp {
  max-width: 600px;
  margin-top: 50px;
  margin-right: auto;
  margin-left: auto;
}

.SignUp .OrderDone p {
  margin-bottom: 1em;
}
