.ConditionEditor {
  position:fixed;
  background-color: white;
  padding:10px;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  display:grid;
  grid-template-columns: 1fr 120px 120px;
  overflow-y: scroll;
  max-height:100%;
}

.ConditionEditor textarea {
  margin:0;
  grid-column-start: 1;
  grid-column-end: 4;
}

.ConditionEditor input {
  margin:0;
  grid-column-start: 1;
  grid-column-end: 4;
}

.ConditionEditor label {
  margin-top:5px;
  grid-column-start: 1;
  grid-column-end: 4;
}

.ConditionEditor .CancelButton {
  width:112px;
  height:32px;
  cursor: pointer;
  background-color: var(--pale-red);
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  text-align: center;
  color: #ffffff;
  grid-column: 2;
}

.ConditionEditor .CreateConditionButton {
  height:28px;
  cursor: pointer;
  background-color: var(--denim);
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  text-align: center;
  color: #ffffff;
  grid-column: 3;
}