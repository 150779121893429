.ToxinIndex {
  border: none;
  padding: 0px 80px 80px 40px;
  width: calc(30vw - 40px);
  text-align: left;
  height: 100%;
  margin-top: 10px;
}

.ToxinIndex .Search {
  display:flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.ToxinIndex .Search .Image {
  max-width:100%;
}

.ToxinIndex .Search input {
  width: calc(100% - 40px);
}

.ToxinIndex .List {
  display: grid;
  grid-template-columns: auto 16px;
  grid-column-gap: 20px;
  margin-left:40px;
}


.ToxinIndex .List .Letters {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.ToxinIndex .List .Letters .Letter.Enabled {
  font-size: 20px;
  font-weight: 800;
  color: var(--dark-indigo);
  cursor: pointer;
  text-decoration: none;
}

.ToxinIndex .List .Letters .Letter.Disabled {
  font-size: 20px;
  font-weight: 400;
  color: var(--dark-indigo);
}

.ToxinIndex .List .Toxins {
  margin:0px;
  padding:0px;
}


.ToxinIndex .List .Toxins .Letter .Heading {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 5px;
  text-align: left;
  color: #000000;
  margin-bottom:10px;
}

.ToxinIndex .List .Toxins .Letter .Toxins {
  margin-bottom:40px;
}

.ToxinIndex .List .Toxins .Letter .Toxins .Toxin .Level0 {
  margin-left: 0px;
  text-align: left;
  cursor: pointer;
  color: var(--dark-indigo);
  font-style: normal;
}

.ToxinIndex .List .Toxins .Letter .Toxins .Toxin .Level0.Disabled {
  font-style: italic;
  cursor: default;
}

.ToxinIndex .List .Toxins .Letter .Toxins .Toxin .Level1 {
  margin-left: 15px;
  font-size:16px;
  text-align: left;
  cursor: pointer;
  color: var(--dark-indigo);
  font-style: normal;
}

.ToxinIndex .List .Toxins .Letter .Toxins .Toxin .Level1.Disabled {
  font-style: italic;
  cursor: default;
}

.ToxinIndex .List .Toxins .Letter .Toxins .Toxin .Level2 {
  margin-left: 15px;
  font-size:15px;
  text-align: left;
  cursor: pointer;
  color: var(--dark-indigo);
  font-style: normal;
}

.ToxinIndex .List .Toxins .Letter .Toxins .Toxin .Level2.Disabled {
  font-style: italic;
  cursor: default;
}

.ToxinIndex .List .Toxins .Letter .Toxins .Toxin .Level3 {
  margin-left: 15px;
  font-size:15px;
  text-align: left;
  cursor: pointer;
  color: var(--dark-indigo);
  font-style: normal;
}

.ToxinIndex .List .Toxins .Letter .Toxins .Toxin .Level3.Disabled {
  font-style: italic;
  cursor: default;
}

.ToxinNameLink {
  cursor: pointer;
}