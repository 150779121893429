.TreatmentActivity {
  padding-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}

.TreatmentActivity .ActivityItem .Remark {
  border: 2px var(--pale-red) solid;
  display: grid;
  grid-template-columns: 32px auto;
  grid-column-gap: 10px;
  margin-top:10px;
  margin-bottom:10px;
  padding: 5px 5px 5px 0px;
  align-items: center;
  font-size:16px;
  font-weight:normal;
}

.TreatmentActivity .ActivityItem .Remark img {
  justify-self: center;
}

.TreatmentActivity .ActivityItem .Remark .Marker {
  color: #d8d8d8;
  font-size:32px;
}

.TreatmentActivity .ActivityDescriptionContainer {
  display: grid;
  grid-template-columns: 32px 400px;
  grid-column-gap: 10px;
  padding-top: 20px;
  padding-bottom: 30px;
  align-items: center;
}

.TreatmentActivity .ActivityDescriptionMarker {
  color: #d8d8d8;
  font-size:32px;
}

.TreatmentActivity .ActivityDescription {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--navy);
  width: 75%;
  white-space: pre-wrap;
}

.TreatmentActivitySeparator {
  padding-bottom:40px;
}

/* Decontamination */

.CaseDetails .TreatmentActivity.Decontamination h3 {
  font-size: 18px;
  color: var(--dark-indigo);
  font-weight: bold;
  margin-top: 40px;
}

.CaseDetails .TreatmentActivity.Decontamination .ActivityDescriptionContainer {
  border: var(--pale-red) 3px solid;
  padding: 10px;
}

.CaseDetails .TreatmentActivity.Decontamination .ActivityDescription {
  padding: 20px 20px 20px 50px;
  border: solid 2px var(--pale-red);
  background: url("./icon-bloodtest.svg") no-repeat center left 15px;
  color: var(--dark-indigo);
  width: calc(100% - 74px);
}
