.UserManager {
  width: 100vw;
}

.UserManager .Content {
  padding: 10px;
}

.UserManager .BottomBar {
  padding: 10px;
  max-width: 1000px;
  margin: 0 auto;
}

.UserManager .BottomBar .Saved {
  margin-top:10px;
  color:green;
  font-style:italic;
  text-align: center;
}

.UserManager .BottomBar .Failed {
  margin-top:10px;
  color:var(--clevor-red);
  font-weight: bold;
  text-align: center;
}

.UserManager .BottomBar .CloseButton {
  width:112px;
  height:32px;
  cursor: pointer;
  background-color: var(--denim);
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  text-align: center;
  color: #ffffff;
  grid-column-start: 1;
  grid-column-end: 2;
}

.UserManager .WelcomeButton {
  margin-left: 5px;
  width: 112px;
  height: 32px;
  padding: 0;
  margin-top: 0;
}

.UserManager .AjaxIndicator img {
  display: none;
}

.UserManager .AjaxIndicator img.show {
  display: block;
}

.UserManager .UserEdit {
  display:grid;
  grid-template-columns: 30px 120px 120px 300px 120px 120px 30px;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  color:black;
  align-items: center;
  margin-top: 10px;
}

.UserManager input {
  margin:0 2px;
}

.UserManager input[name=firstName] {
  width: 12ch;
}

.UserManager input[name=lastName] {
  width: 15ch;
}

.UserManager input[name=email] {
  width: 30ch;
}

.UsersHeading, .UsersRegionSelector, .UserFilter {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  margin-bottom: 0;
}

.UsersRegionSelector > label {
  display: flex;
  align-items: center;
  margin-right: 2em;
  font-size: 1.1em;
}

.UsersRegionSelector input {
  margin: 0 5px;
}

.UsersTable {
  margin: 30px auto;
  border-collapse: collapse;
}

.UsersTable td, .UsersTable th {
  padding-left: 5px;
  padding-right: 5px;
}

.UsersTable td {
    padding: 5px;
    border-bottom: 1px solid var(--clevor-blue);
}

.UsersTable tr {
  height: 40px;
  vertical-align: top;
}

.UsersTable thead tr th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: var(--app-bg-color);
  text-align: center;
}

.UsersTable td.INACTIVE,
.UsersTable td.ENDING,
.UsersTable td.ACTIVE {
    font-weight: bold;
    color: var(--clevor-blue);
}

.UsersTable td.ENDING {
  color: var(--moderate-font);
}

.UsersTable td.INACTIVE {
    color: var(--clevor-red);
}

.UserEditRow td:first-child {
  text-align: center;
}