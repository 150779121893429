.ActivityItemEditor {
  grid-column-start: 1;
  grid-column-end: 4;
  margin:5px;
  padding:10px;
  border-radius:10px;
  background-color: var(--app-bg-color);
  display:grid;
  grid-template-columns:100%;
}

.ActivityItemEditor .ItemList {
  display:grid;
  grid-template-columns: 34px 1fr 34px;
  align-items: start;
  grid-row-gap: 10px;
}

.ActivityItemEditor .ItemList span {
  grid-column: 2;
}

.ActivityItemEditor .ItemList input {
  grid-column-start: 2;
  grid-column-end: 2;
}

.ActivityItemEditor label {
  padding-top:5px;
  padding-bottom:4px;
  font-size:12px;
}

.ActivityItemEditor .RemoveButton {
  grid-column-start: 1;
  grid-column-end: 1;
}

.ActivityItemEditor .ItemList .TextEdit {
  width:100%;
}

.ActivityItemEditor .ItemList .TextEditContainer {
  width:100%;
  grid-column-start: 2;
  grid-column-end: 2;
}

.ActivityItemEditor .ItemList .RemoveButton {
  margin:0;
}

.ActivityItemEditor .ItemList .MoveButton {
  padding-top:3px;
  margin:0;
  grid-column-start: 3;
  grid-column-end: 3;
}

.ActivityItemEditor .ItemList .AddButton {
  grid-column-start: 1;
  grid-column-end: 1;
  margin:0;
}

.ActivityItemEditor .ItemControls {
  grid-column-start: 1;
  grid-column-end: 2;
}

.ActivityItemEditor .ItemControls .RemoveButton {
  width: 130px;
}

.ActivityItemEditor .NameAsHeadingWrapper {
  display: flex;
  align-items: center;
}

.ActivityItemEditor .NameAsHeadingWrapper input {
  margin-left: 15px;
  margin-right: 5px;
}

.ActivityItemEditor .NameAsHeadingWrapper input:first-child {
  margin-left: 0;
}
