.Login .grid {
  grid-template-columns: 1fr 1fr;
}

.Login .Buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: span 2;
}

.Login .PasswordResetContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Login .SignUpLinkContainer {
  color: var(--denim);
  font-size: 14px;
  text-align: right;
  background-color: transparent;
  height: auto;
}

.Login .SignUpLink {
  color: var(--denim);
  font-weight: bold;
  text-transform: uppercase;
}

.Login .Buttons .ForgotPasswordButton {
  cursor: pointer;
  color: var(--denim);
  font-size: 14px;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  padding: 0;
  height: auto;
  margin-bottom: 0.2em;
}

.Login .Buttons .ForgotPasswordButton[disabled] {
  cursor: default;
}

.Login .InvalidCredentials {
  color: var(--clevor-red);
  grid-column: span 2;
}

.Login .PasswordResetSent {
  color: green;
  grid-column: span 2;
}

.Login .NotCustomer {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Login .UserExists {
  color: var(--clevor-red);
  grid-column: span 2;
  font-weight: bold;
}

.Login .OrderFailed {
  color: var(--clevor-red);
  grid-column: span 2;
  font-weight: bold;
}

.Login .NotCustomer .Text {
  color: var(--dark-indigo);
  font-weight: bold;
  letter-spacing: var(--header-letter-spacing);
}

.Login .NotCustomer p {
  margin-top: 0.5em;
  font-size: 0.9em;
}

.Login .OrderButton {
  grid-column: span 2;
}

.Login .Loading {
  z-index: 10001;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: transparent;
}

.Login .Loading .Background {
  position: relative;
  background-color: rgba(128, 128, 128, 0.5);
  min-width: 100%;
  min-height: 100%;
}

.Login .Loading .Text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: var(--dark-indigo);
  border-radius: 15px;
  padding: 20px;
  font-size: 32px;
}

.LoginPageLogo {
  margin-top: 20px;
  height: 60px;
}

.TaglineWrapper {
  background: url('../../images/koira.png') top right no-repeat;
  min-height: 330px;
}

.Tagline {
  text-transform: uppercase;
  color: var(--clevor-red);
  font-size: 70px;
  margin-top: 0;
  margin-bottom: 0;
}

.SubTagline {
  text-transform: uppercase;
  color: var(--clevor-blue);
  margin-top: 0;
  margin-bottom: 20px;
}
