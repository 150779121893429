.SignConditionEditor {
  font-size:18px;
}

.SignConditionEditor .List {
  margin-top: 5px;
  display:grid;
  grid-template-columns: 36px 1fr;
  align-items: center;
  grid-row-gap: 3px;
}

.SignConditionEditor .List .ButtonContainer {
  margin-top: 5px;
  margin-bottom: 10px;
}

.SignConditionEditor .List .AddCondition {
  grid-column-end: 2;
  display:grid;
  grid-template-columns: 200px 1fr;
  grid-column-gap: 5px;
  align-items: center;
  margin-top:3px;
  margin-bottom:10px;
}

.SignConditionEditor .List select {
  align-items: center;
}

.SignConditionEditor .DropdownBox {
  position: relative;
  border: none;
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: var(--denim);
  color: white;
  cursor: pointer;
  user-select: none;
  padding:2px;
  margin:0px;
}

.SignConditionEditor .DropdownBox.Open {
}

.SignConditionEditor .DropdownPane {
  border: none;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000000;
  background-color: #ffffff;
}