.IngredientEditor {
  display: grid;
  grid-template-columns: 30px 300px 70px 50px 70px 50px;
  align-items: center;
  grid-column-gap: 5px;
  margin-top: 10px;
}

.IngredientEditor input {
  margin-top:5px;
}

.IngredientEditor button {
  margin-top:5px;
}

.IngredientEditor .Substance {
  margin:2px 10px 0px 0px;
  width:300px;
}