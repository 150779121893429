.TopBar {
  color: var(--clevor-blue);
  width: 100vw;
}

.TopBar .WhiteTopBar {
  background-color: #ffffff;
}

.TopBar .DogLogo {
  height: 70px;
  margin-right: 50px;
}

.TopBar .HeaderSlice {
  width: 100vw;
}

.TopBar .TopBarWrapper {
  margin:auto;
  max-width:1000px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}

.TopBar .WhiteTopBar .TopBarWrapper {
  justify-content: flex-start;
}

.TopBar .title {
  width: 210px;
  order: 0;
}

.TopBar .links {
  width: calc(100% - 210px);
  order: 2;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  letter-spacing: var(--header-letter-spacing);
}

.TopBar select {
  min-height:1.5em;
  font-size: 1.3em;
  border: none;
}

.TopBar .links * {
  margin-left: 0.5em;
}

.TopBar .links a {
  color: var(--clevor-blue);
  text-decoration: none;
  padding: 0.4em;
}

.TopBar .Admin {
  cursor: pointer;
  order: 5;
}

.TopBar .Account {
  cursor: pointer;
  order: 4;
}

.TopBar .FeedbackPage {
  cursor: pointer;
  order: 3;
}

.TopBar .Home {
  cursor: pointer;
  order: 1;
}

.TopBar .SignUpLink,
.TopBar .LogOut {
  cursor: pointer;
  order: 6;
}

.TopBar .Library {
  cursor: pointer;
  order: 2;
}

.TopBar .Library .sub {
  vertical-align: sub;
  font-size: 12px;
  margin-left: 0px;
}

.TopBar .MainLogo {
  height: 40px;
}

.TopBar .links a.selected {
  color: white;
  background: var(--clevor-red);
}
