.SubscriptionRenewalForm label.Horizontal {
  align-items: center;
  flex-direction: row;
}

.SubscriptionRenewalForm label.us-subscription {
  color: var(--clevor-red);
  font-size: 1em;
  margin: 0;
  padding: 0 6px;
}

.SubscriptionRenewalForm .SubscriptionPrice {
  margin: .5em 0;
}

.SubscriptionRenewalForm .SubscriptionNormalPrice {
  font-size: .8em;
  margin-left: 0.3em;
}

.SubscriptionRenewalForm .RenewButton.ConfirmButton {
  background-color: var(--clevor-red);
}

.SubscriptionRenewalForm input[type="radio"],
.SubscriptionRenewalForm input[type="checkbox"] {
  margin-right: .5em;
}
