* {
  margin: 0;
  padding: 0;
}

*:not(input):not(select):not(textarea) {
  user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

html, body {
  color: var(--navy);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  background-color: var(--app-bg-color);
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

h1, h2, h3, h4, h5 {
  color: var(--dark-indigo);
  letter-spacing: var(--header-letter-spacing);
}

h1 {
  font-weight: 800;
  font-size: 24px;
}

h2 {
  font-size: 18px;
  font-weight: bold;
}

h2.header-add-bottom-margin {
  margin-bottom: var(--header-add-bottom-margin);
}

h3 {
  color: var(--denim);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
}

h4 {
  color: var(--denim);
  font-size: 24px;
  font-weight: 100;
}

label {
  font-size: 14px;
  font-weight: 600;
  margin-top: 2px;
  margin-bottom: 2px;
  color: var(--denim);
  letter-spacing: var(--header-letter-spacing);
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

input,
.StripeElement {
  height: 32px;
  padding: 8px 6px;
  box-sizing: border-box;
  color: var(--denim);
  background-color: #fff;

}

textarea {
  padding: 6px;
  color: var(--denim);
}

input,
textarea,
.StripeElement {
  border: 1px solid var(--pale-teal);
}

label, input {
  text-align: left;
}

select {
  color: var(--denim);
  background-color: #ffffff;
  border: 1px solid var(--denim);
  height: 32px;
}

button {
  color: #ffffff;
  height: 44px;
  background-color: var(--denim);
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 16px;
  border-color: transparent;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: bold;
  letter-spacing: 0.7px;
}

button[disabled] {
  opacity: 0.4;
  cursor: default;
}

.grid {
  display: grid;
  grid-gap: 4px 6px;
  align-items: center;
}

.hidden {
  display: none;
}
