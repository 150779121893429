.BillingInformationForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.BillingInformationForm label.OneThird {
  width: 33.33333%;
}

.BillingInformationForm label.TwoThirds {
  width: 66.66666%;
}

.BillingDetails .BillingInformationForm .validationErrorText {
  margin-bottom: 0px;
}

.BillingAddressForm {
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
