.DropDownContainer {
  margin-bottom:4px;
}

.DropDownContainer .Button {
  top:30%;
  right:20px;
  position:absolute;
  cursor:pointer;
  color:var(--pale-red);
  font-weight:bold;
}
