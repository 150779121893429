.TreatmentPlan {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.25;
  position:relative;
}

.TreatmentPlan h2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.TreatmentPlan h3 {
  margin-top: 20px;
  margin-bottom: 8px;
}

.TreatmentPlan .DropdownBox {
  position: relative;
  border: none;
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 12px 12px 12px 20px;
  margin-bottom:15px;
  background-color: #ffffff;
  color: var(--dark-indigo);
  cursor: pointer;
  user-select: none;
  font-weight: bold;
}

.TreatmentPlan .DropdownBox.Open {
  background-color: var(--case-details-activity-background-color);
  color: #ffffff;
  margin-bottom:0px;
}

.TreatmentPlan .DropdownPane {
  border: none;
  padding-left: 40px;
  padding-bottom: 40px;
  padding-top: 30px;
  padding-right: 60px;
  margin-top: -10px;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 15px;
  background-color: #ffffff;
}

.TreatmentPlan .DropDownContainer .Button {
  top:30%;
  right:20px;
  position:absolute;
  cursor:pointer;
  color:var(--pale-red);
  font-weight:bold;
}

.TreatmentPlan .AdminItem {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid var(--dark-indigo);
  border-radius: 8px;
}

.TreatmentPlan .DescriptionBlockFlexWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 40px;
}

.TreatmentPlan .DescriptionBlockFlexWrapper .LeftColumn {
  width: 70%;
}

.TreatmentPlan .DescriptionBlockFlexWrapper .LeftColumn .DescriptionBlockSynonyms {
  color: var(--denim);
  border-bottom: 1px solid var(--denim);
  font-size: 14px;
  margin-bottom: 10px;
}

.TreatmentPlan .DescriptionBlockFlexWrapper .LeftColumn .DescriptionBlockSynonyms .DescriptionBlockSynonymsTitle {
  font-style: italic;
  margin-right: 15px;
}

.TreatmentPlan .DescriptionBlockFlexWrapper .LeftColumn .DescriptionBlockSynonyms .DescriptionBlockSynonymsContent {
  font-weight: bold;
}

.TreatmentPlan .DescriptionBlockFlexWrapper .LeftColumn .DescriptionBlockDescription {
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.TreatmentPlan .DescriptionBlockFlexWrapper .RightColumn {
  width: 25%;
  color: var(--denim);
  margin-top: 20px;
}

.TreatmentPlan .DescriptionBlockFlexWrapper .RightColumn div {
  text-align: right;
  font-style: italic;
  margin-bottom: 3px;
}