.PasswordReset .grid {
  grid-template-columns: 1fr;
}

.PasswordReset .validationError {
  color: var(--clevor-red);
  font-size: 0.8em;
}

.PasswordReset .failed {
  color: var(--clevor-red);
  font-size: 0.8em;
}
