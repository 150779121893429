.CreateActivity {
  background-color: var(--app-bg-color);
  padding: 10px;
  margin-top:20px;
  margin-bottom: 10px;
  border-radius:8px;
}

.CreateActivity button {
  padding-left:15px;
  padding-right:15px;
  height:32px;
  cursor: pointer;
  background-color: var(--denim);
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  margin: 0;
}

.CreateActivity select {
  margin-right: 30px;
  min-width: 200px;
}

.CreateActivity .DropDown {
  display:flex;
}

.CreateActivityMulti {
  background-color: whitesmoke;
  padding:5px 10px 5px 10px;
  margin:10px;
  grid-template-columns: 50% 50%;
  grid-column-gap: 5px;
  display:grid;
}

.CreateActivityMulti button {
  width:100%;
  margin:0px;
  padding-left:15px;
  padding-right:15px;
  height:32px;
  cursor: pointer;
  background-color: var(--denim);
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  text-align: center;
  color: #ffffff;
}